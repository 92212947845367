import React,{useState} from 'react'
import  "./gamemodal.css";
import 'react-responsive-modal/styles.css';
import Modal from 'react-bootstrap/Modal';
import TextField from '@mui/material/TextField';
import {Button,Form} from 'react-bootstrap';
import { Link } from "react-router-dom"
import Stripe from '../services/Stripe';
const bg = {
  customModal: {
    background: "#00000000"
  }
};
function Paymentmodal(props) {
    const [amount,setAmount]=useState('');
    const [payment,setPayment]=useState('');
    const [error,setError]=useState('');
    const [showPaymentForm,SetShowPaymentForm]=useState(false);
    const submitPayment=()=>{
       if(amount==''){
        setError('*Please Enter Amount');
      }
     else if(payment=='')
      {
        setError('*Please Select Payment Type');
      }
     
      else{
       props.paymentStripe(payment,amount);
      }
    }
   
  return (
    <div>
      
       <Modal show={props.show} onHide={props.onHide} backdrop="static"
        keyboard={false}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered dialogClassName="my-modal" contentClassName="modalPAyment">
        <Modal.Header closeButton style={{position: 'absolute',backgroundColor: '#ffffff',
        right: 0,borderRadius: '40px',padding: '7px 3px',zIndex:10}}>
       
        </Modal.Header>
        
        <Modal.Body>
         
        <div className='paymentModal'>
        <div className='paymentModalDiv'>
         <div className='paymentLable'>
            Enter Amount: 
         </div>
         <div className='ErrorLbl'>{error}</div>
         <input
          type="number" 
          value={amount}
          onChange={(e) => setAmount(e.target.value)} 
          className='paymentInput'
         />
         <div>
         <div style={{width:'100%',marginTop:'15px'}}>
          <Button variant="success" className={payment==='cashapp'?'btnRadioCashActive':'btnRadioCash'} 
          onClick={()=>setPayment('cashapp')}>
              Cash App
          </Button>
          <Button variant="success" className={payment==='debit'?'btnRadioCashActive':'btnRadioCash'}
            onClick={()=>setPayment('debit')}>
              Debit Card 
          </Button>
        </div>
      
        
         </div>
         <div style={{width:'100%',marginTop:'15px'}}>
         <Button variant="success" className='btnJoin' onClick={()=>submitPayment()}>
            Submit
          
        </Button>

         </div>
        </div>
       
      </div>
        </Modal.Body> 
        
      </Modal>

    
      </div>
    
  )
}

export default Paymentmodal;