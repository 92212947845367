// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header_navbar__ClolQ {\n    display: flex;\n    justify-content: flex-start;\n    gap: 20px;\n    align-items: center;\n    /* padding: 10px 20px; */\n    color: white;\n    /* height: 100px; */\n    background-color: #182035;\n    height: 80px;\n  }\n  \n  .header_navbar__ClolQ > img {\n    width: auto;\n    height: 100%;\n  }\n  .header_navbar__ClolQ > img:last-child {\n    width: 200px;\n  }\n  \n  .header_navbar__ClolQ .header_link__syKgd {\n    color: cyan;\n    font-size: 1.2rem;\n  }\n  ", "",{"version":3,"sources":["webpack://./src/component/Header/header.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2BAA2B;IAC3B,SAAS;IACT,mBAAmB;IACnB,wBAAwB;IACxB,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,WAAW;IACX,YAAY;EACd;EACA;IACE,YAAY;EACd;;EAEA;IACE,WAAW;IACX,iBAAiB;EACnB","sourcesContent":[".navbar {\n    display: flex;\n    justify-content: flex-start;\n    gap: 20px;\n    align-items: center;\n    /* padding: 10px 20px; */\n    color: white;\n    /* height: 100px; */\n    background-color: #182035;\n    height: 80px;\n  }\n  \n  .navbar > img {\n    width: auto;\n    height: 100%;\n  }\n  .navbar > img:last-child {\n    width: 200px;\n  }\n  \n  .navbar .link {\n    color: cyan;\n    font-size: 1.2rem;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbar": "header_navbar__ClolQ",
	"link": "header_link__syKgd"
};
export default ___CSS_LOADER_EXPORT___;
