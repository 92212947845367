import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
// import Form from "react-bootstrap/Form";
// import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import homeCSS from "./home.module.css";
import { Slide } from "./Slide";
// import Fab from "@material-ui/core/Fab";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Link } from "react-router-dom";
// import Paymentmodal from "../../component/Paymenetmodal";
// import Stripe from "../../services/Stripe";
import Footer from "../../component/Footer/Footer";
import Navbar from "../../component/Navbar";

const data = [
  {
    image: "../assets/web/game/1.jpg",
  },
  {
    image: "../assets/web/game/2.jpg",
  },
  {
    image: "../assets/web/game/3.jpg",
  },
];

const CarouselComponent = () => {
  const ref = React.useRef();

  useEffect(() => {
    let interval = setInterval(() => {
      ref.current?.goNext();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Col
      xs={12}
      md={5}
      lg={5}
      style={{ position: "relative", padding: "0px 10px 0px 35px" }}
    >
      <KeyboardArrowLeftIcon
        className={homeCSS.leftArrow}
        onClick={() => ref.current?.goBack()}
      />

      <ResponsiveContainer
        carouselRef={ref}
        render={(width, carouselRef) => {
          return (
            <StackedCarousel
              ref={carouselRef}
              slideComponent={Slide}
              slideWidth={450}
              carouselWidth={width}
              data={data}
              maxVisibleSlide={5}
              disableSwipe={false}
              customScales={[1, 0.85, 0.7, 0.55]}
              transitionTime={450}
            />
          );
        }}
      />

      <KeyboardArrowRightIcon
        className={homeCSS.rightArrow}
        onClick={() => ref.current?.goNext()}
      />
    </Col>
  );
};

function Home() {
  // const ref = React.useRef(StackedCarousel);
  // const ref2 = React.useRef(StackedCarousel);
  // const ref3 = React.useRef(StackedCarousel);
  // const [gameurl, Setgameurl] = useState("");
  // const [showDeposit, setShowDeposit] = useState(false);
  // const [showStripePayment, setShowStripePayment] = useState(false);
  // const [showPayment, setShowPayment] = useState(false);

  // const hidePayment = () => {
  //   setShowPayment(false);
  // };

  return (
    <div className={homeCSS.homeModule}>
      <Navbar />

      <div style={{ textAlign: "left" }}>
        <Row style={{ padding: "0px", margin: "0px" }}>
          <img
            className={homeCSS.graphics_image}
            src="./assets/topLine.png"
            alt="aerodynamic"
          />
        </Row>
        <Row className={homeCSS.flex} style={{ margin: "0px" }}>
          <Col xs={4} md={5} lg={5} style={{ paddingLeft: "0px" }}>
            <img
              src="./assets/web/girl_img.png"
              alt="aerodynamic"
              className={homeCSS.homeGirl}
            />
          </Col>
          <Col xs={8} md={7} lg={7}>
            <div className={homeCSS.title}>Aerodynamic</div>
            <div style={{ position: "relative" }}>
              <img
                src="./assets/headImage.png"
                alt="aerodynamic"
                className={homeCSS.homeTop}
              />
            </div>
            <div className={homeCSS.descTop}>
              Welcome to Aerodynamic Casino LLC. We provide a selection of
              online casino-style games based on Roulette, Blackjack, Video
              Poker, Table Games and Big Jackpot slots. Get straight to action
              through our easy to play web browser games for your PC, Mac or
              mobile device.
            </div>
            <Button variant="success" className={homeCSS.btnJoin}>
              <Link
                to="/Splash"
                style={{ color: "#ffffff", textDecoration: "none" }}
              >
                Play Web Game!
              </Link>
            </Button>
          </Col>
        </Row>

        <Container className={homeCSS.gameSection}>
          <Row style={{ padding: "0px", margin: "0px" }}>
            <Col xs={12} md={7} lg={7}>
              <div className={homeCSS.gameTitle}>
                Aerodynamic
                <br />
                casino games
              </div>
              <div>
                <div className={homeCSS.gameDtls}>
                  It couldn't be easier to play free casino-style games online
                  with us. Not only is it completely free to create an account,
                  but we also have hundreds of games to pick from, with new
                  casino-style games being added virtually every week. Therefore
                  whichever online casino-style game you prefer—Between
                  Blackjack, Roulette, and Stampeding Buffalo Slots—you'll
                  always discover something new, and ideally, something you
                  enjoy!
                  <br />
                  <br />
                </div>
              </div>
            </Col>
            <CarouselComponent />
          </Row>
        </Container>

        <Container className={homeCSS.gameSection}>
          <Row>
            <CarouselComponent />
            <Col xs={12} md={5} lg={5}>
              <div className={homeCSS.gameTitle}>
                Aerodynamic
                <br />
                fishing games
              </div>
              <div>
                <div className={homeCSS.gameDtls}>
                  Look nowhere else for fish table gambling sites if you're
                  seeking for a new method to play online fish table games.
                  Online fish table games are simple to begin with and enjoyable
                  to play! With so many fish games to choose from, you will
                  always be able to pick one with a high payout.
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <Container className={homeCSS.gameSection}>
          <Row>
            <Col xs={12} md={7} lg={7}>
              <div className={homeCSS.gameTitle}>
                Aerodynamic
                <br />
                slot games
              </div>
              <div>
                <div className={homeCSS.gameDtls}>
                  Join in the fun by playing slot games at Aerodynamic Casino!
                  With so many slot games to select from, you'll always have an
                  enjoyable experience.
                </div>
              </div>
            </Col>
            <CarouselComponent />
          </Row>
        </Container>
        <Container className={homeCSS.gameDownload} id="sectionDownload">
          <Row style={{ margin: "0px", padding: "0px" }}>
            <Col xs={12} md={12} lg={12} style={{ textAlign: "center" }}>
              <div className={homeCSS.gameDownDivTitle}>Get the App</div>
              <div className={homeCSS.gameDownButtonDiv}>
                <Row style={{ paddingTop: "30px" }}>
                  <Col xs={12} md={6} d="flex" lg={6}>
                    <div>
                      <a
                        href="./application/Aerodynamic.apk"
                        // href="https://www.dropbox.com/s/a3k09qklraa38ed/Aerodynamic.apk?dl=1"
                        // target="_blank"
                      >
                        <img
                          src="./assets/web/btn_android.png"
                          alt="aerodynamic"
                          className={homeCSS.Downbtn}
                        />
                      </a>
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <div>
                      <img
                        src="./assets/web/btn_ios.png"
                        alt="aerodynamic"
                        className={homeCSS.DownbtnIos}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <img
                className={homeCSS.buttomGraohic}
                src="./assets/web/bottom_graphic.png"
                alt="aerodynamic"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Row style={{ padding: "0px", margin: "0px" }}>
        <img
          className={homeCSS.graphics_image}
          src="./assets/bottomLine.png"
          alt="aerodynamic"
        />
      </Row>
      <Container>
        <Row>
          <Col xs={12} style={{ textAlign: "center", color: "#ffffff" }}>
            <div className={homeCSS.footerDiv}>
              <Footer />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Home;
