import React from "react";
import {
  AiOutlineInstagram,
  AiOutlineFacebook,
  AiOutlineMail,
} from "react-icons/ai";
import "./footer.css";
function Footer() {
  return (
    <div className="d-flex align-items-center justify-center footer__wrapper ">
      <ul className="d-flex gap-4 justify-center align-items-center">
        <li>
          <p className="text-lg d-flex mb-0    align-items-center ">
            Copyright @ Aerodynamic LLC 2023. All Right Reserved
          </p>
        </li>
        <li className="ml-auto">
          <div className="d-flex text-white  text-2xl gap-3">
            <a
              href="https://www.instagram.com/aerodynamiccasinollc/"
              className="text-white"
            >
              <AiOutlineInstagram />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61550863261820"
              className="text-white"
            >
              <AiOutlineFacebook />
            </a>
            <a
              href="mailto:aerodynamiccasinollc@gmail.com"
              className="text-white"
            >
              <AiOutlineMail />
            </a>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default Footer;
