import React, { useEffect, useState } from "react";
import Navbar from "../../component/Navbar";
import axios from "../../services/axios";
import "./blog.css";
import { Link } from "react-router-dom";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    axios.get("/admin/getBlog").then((res) => {
      if (res) {
        setBlogs(res.data.data);
      }
    });
  }, []);

  return (
    <div className="container__wrapper">
      <Navbar />
      <main className="main__container">
        <h1>Blogs</h1>
        <div className="blog__list__wrapper">
          <ul className="blog__list">
            {blogs.map((item) => {
              return (
                <li className="blog__list__item">
                  <Link
                    to={`/blog/${item._id}`}
                    className="blog__list__item__link"
                  >
                    <img src={item.image} alt="" />
                    <p className="blog__list__item__title">
                      <strong>{item.title}</strong>
                    </p>
                    <p className="blog__list__item__sub__title">
                      {item.sub_title}
                    </p>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </main>
    </div>
  );
};

export default Blog;
