import React from "react";
import Container from "react-bootstrap/Container";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Footer from "../../component/Footer/Footer";
import Navbar from "../../component/Navbar";
import homeCSS from "./home.module.css";

function Contact() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#182035",
      }}
    >
      <Navbar />
      <div style={{ textAlign: "left", flex: 1 }}>
        <Row style={{ padding: "0px", margin: "0px" }}>
          <img
            className={homeCSS.graphics_image}
            src="./assets/topLine.png"
            alt="aerodynamic"
          />
        </Row>
        <Container>
          <Row style={{ marign: "0px", padding: "0px" }}>
            <Col xs={4}></Col>
          </Row>
        </Container>

        <section className={homeCSS.main__section}>
          <div>
            <h2 className={homeCSS.title}>Aerodynamic</h2>
            <h3 className={homeCSS.Contitle}>Contact us</h3>
            <div className={homeCSS.contact__info}>
              <img
                src="./assets/web/msg.png"
                alt="aerodynamic"
                className={homeCSS.icon}
              />
              <p style={{ color: "#ffffff" }}>aerodynamiccasinollc@gmail.com</p>
            </div>
          </div>

          <div className={homeCSS.image__container}>
            <img src="./assets/web/girl_img.png" alt="aerodynamic" />
            <img src="./assets/web/pngwing.png" alt="aerodynamic" />
          </div>
        </section>
      </div>
      <Row style={{ padding: "0px", margin: "0px" }}>
        <img
          className={homeCSS.graphics_image}
          src="./assets/bottomLine.png"
          alt="aerodynamic"
        />
      </Row>
      <Footer />
    </div>
  );
}

export default Contact;

// <Container className={homeCSS.gameDownload}>
//           <Row style={{ margin: "0px", padding: "0px" }}>
//             <Col xs={12} style={{ textAlign: "center" }}>
//               <div className={homeCSS.gameDownDivTitle}>
//                 <div className={homeCSS.title}>Aerodynamic</div>
//                 <div className={homeCSS.Contitle}>Contact Us</div>
//               </div>
//               <div className={homeCSS.gameDownContactDiv}>
//                 {/*<div className={homeCSS.contactDiv}>
//                    <img  src="./assets/web/tel.png" alt="aerodynamic" className={homeCSS.icon}/>
//                    &nbsp;&nbsp;&nbsp;<span style={{color:'#ffffff'}}>619-720-2532</span>
//   </div>*/}
//                 <div className={homeCSS.contactDiv}>
//                   <img
//                     src="./assets/web/msg.png"
//                     alt="aerodynamic"
//                     className={homeCSS.icon}
//                   />

//                   <span style={{ color: "#ffffff" }}>
//                     aerodynamiccasinollc@gmail.com
//                   </span>
//                 </div>
//               </div>

//             </Col>
//           </Row>
//         </Container>
