import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import AboutUs from "./screen/AboutUs/AboutUs";
import Blog from "./screen/Blog/Blog";
import Contact from "./screen/Contact/Contact";
import Game from "./screen/Game/Game";
import GameList from "./screen/GameList/GameList";
import Home from "./screen/Home/Home";
import Login from "./screen/Login/Login";
import Splash from "./screen/Splash/Splash";
import PrivateRoute from "./utilis/HOC/PrivateRoute";
import SingleBlog from "./screen/Blog/SingleBlog";

const App = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/Contact" element={<Contact />} />
          <Route exact path="/Splash" element={<Splash />} />
          <Route exact path="/login" element={<Login />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:blog_id" element={<SingleBlog />} />

          <Route path="*" element={<Home />} />

          <Route exact element={<PrivateRoute />}>
            <Route exact path="/gamelist" element={<GameList />} />
            <Route exact path="Game/:Id" element={<Game />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
};

export default App;
