import React, { useState } from "react";
import "../CashbackSpinner/style.css";
import { Wheel } from "react-custom-roulette";
import { Button } from "react-bootstrap";
import axios from "../../../services/axios";
import { toast } from "react-hot-toast";

const DailySpin = () => {
  const [mustSpin, setMustSpin] = useState(false);
  const [winner, setWinner] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [isEligibleForSpin, setIsEligibleForSpin] = useState(false);

  const data = [
    {
      option: "WIN",
      style: { backgroundColor: "#FD4C3A", textColor: "white" },
    },
    {
      option: "LOSE",
      style: { backgroundColor: "#029ADD", textColor: "white" },
    },
    {
      option: "WIN",
      style: { backgroundColor: "#FD4C3A", textColor: "white" },
    },
    {
      option: "LOSE",
      style: {
        backgroundColor: "#02CB6B",
        textColor: "white",
      },
    },
    {
      option: "WIN",
      style: { backgroundColor: "#029ADD", textColor: "white" },
    },
    {
      option: "LOSE",
      style: { backgroundColor: "#FD4C3A", textColor: "white" },
    },
    {
      option: "WIN",
      style: { backgroundColor: "#02CB6B", textColor: "white" },
    },
    {
      option: "LOSE",
      style: { backgroundColor: "#029ADD", textColor: "white" },
    },
  ];

  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const creditUser = ({ username, amount, transactionType }) =>
    axios.post("addRemoveCredit", {
      userName: username,
      amount: amount,
      transactionType: transactionType,
    });

  const fetchPlayerDailySummary = (username) =>
    axios
      .post("playerDailySummary", {
        userName: username,
        date: new Date(),
        page: 1,
        limit: 100,
      })
      .then((res) => {
        console.log({ data: res.data });

        if (res.data.response.Data.length > 0) {
          setIsEligibleForSpin(true);
          // setModalVisible(true);
        } else {
        }
      });

  const fetchCanSpin = (username) =>
    axios
      .post("checkDailySpin", {
        username: username.toString(),
      })
      .then((data) => {
        if (data.data.success) {
          fetchPlayerDailySummary(username);
        }
      })
      .catch((error) => {
        // toast.error("You are not eligible for daily spin");
        setIsEligibleForSpin(false);
      });

  const fetchPercentage = () =>
    axios.get("fetchPercentageValue").then((data) => {
      if (data.data.success) {
        console.log({ cashbackPercentage: data.data.data.cashbackPercentage });

        let userData = localStorage.getItem("userData");

        if (userData) {
          userData = JSON.parse(userData);
          creditUser({
            username: userData.Username,
            amount: (data.data.data.DailySpinPercentage * 100) / 100,
            transactionType: "credit",
          });
        }
      }
    });

  return (
    <>
      <div
        onClick={() => {
          let userData = localStorage.getItem("userData");

          if (userData) {
            userData = JSON.parse(userData);
            fetchCanSpin(userData.Username);
            setModalVisible(true);
          }
        }}
        className="spinner__modal__button__container dailySpin"
      >
        <button className="spinner__modal__button dailyWheel">
          <p>Daily Wheel</p>
        </button>
      </div>

      {modalVisible && (
        <div
          className="modal__container"
          onClick={() => {
            setModalVisible(false);
          }}
        >
          <div
            className="modal__content"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <Wheel
              mustStartSpinning={mustSpin}
              prizeNumber={winner}
              data={data}
              radiusLineWidth={0}
              backgroundColors={["#3e3e3e", "#df3428"]}
              textColors={["#ffffff"]}
              pointerProps={{
                src: "./assets/knob.png",
                style: {
                  width: "80px",
                  rotate: "45deg",
                },
              }}
              fontWeight="bold"
              outerBorderWidth={20}
              outerBorderColor="#22485F"
              onStopSpinning={() => {
                if (!isEligibleForSpin) {
                  setMustSpin(false);
                  toast.error("Better luck next time!");
                } else {
                  if (data[winner].option.toLocaleLowerCase() === "win") {
                    fetchPercentage();
                    // set 100 amount to user
                  }

                  setMustSpin(false);
                }
              }}
            />
            <Button
              className="spin_button"
              onClick={() => {
                if (mustSpin) {
                  return;
                }

                if (!isEligibleForSpin) {
                  setMustSpin(true);
                  setWinner(3);

                  return;
                }

                setMustSpin(true);
                setWinner(randomIntFromInterval(0, data.length - 1));
                // setWinner(0);
              }}
            >
              Spin
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default DailySpin;
