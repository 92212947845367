/* eslint-disable jsx-a11y/no-distracting-elements */
import { encode as base64_encode } from "base-64";
import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import ChangePassModal from "../../component/ChangePassModal";
import Depositmodal from "../../component/Depositmodal";
import ErrorModal from "../../component/ErrorModal";
import Gamemodal from "../../component/Gamemodal";
import GameplayModal from "../../component/GameplayModal";
import Paymentmodal from "../../component/Paymenetmodal";
import DailySpin from "../../component/Spinner/DailySpin/DailySpin";
import PasswordModal from "../../component/passwordModal";
import UserModal from "../../component/userModal";
import Stripe from "../../services/Stripe";
import axios from "../../services/axios";
import { game_url } from "../../services/constants";
import {
  userChangePassword,
  userLoginError,
  userLogout,
} from "../../store/actions/authAction";
import { checkUserCredit, getGameList } from "../../store/actions/gameAction";
import "./gameList.css";

// Import Swiper styles
import "swiper/css";

import ReactSound from "react-sound";
import CashbackSpinner from "../../component/Spinner/CashbackSpinner/CashbackSpinner";

const GameList = () => {
  const Errdata = useSelector((state) => state.authRoot.ErrMessage);

  const [backgroundMusicStatus, setBackgroundMusicStatus] = useState("PLAYING");

  const [modalShow, setModalShow] = useState(false);
  const gameData = useSelector((state) => state.gameRoot.gameList);
  const gameLoading = useSelector((state) => state.gameRoot.gameLoading);
  const [gamelist, setgameList] = useState([]);
  const [userData, setuserData] = useState(localStorage.getItem("userData"));
  const notificationData = useSelector((state) => state.authRoot.notification);
  const [userCredit, setUserCredit] = useState({});

  const [showDeposit, setShowDeposit] = useState(false);
  const [showRedeem, setshowRedeem] = useState(false);
  const [showStripePayment, setShowStripePayment] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [depoamt, setDepoamt] = useState(0);
  const [paymentErr, setPaymentErr] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const [show, setShow] = useState(false);
  const [gameurl, Setgameurl] = useState("");
  const userAcount = useSelector((state) => state.gameRoot.userCredit);
  const [userName, setUserName] = useState("");
  const [showUserModal, setShowUserModal] = useState(false);
  const [changePassmodal, setChangePassmodal] = useState(false);
  const [ModalGameShow, setModalGameShow] = useState(false);
  const [showPasswordModal, setshowPasswordModal] = useState(
    !localStorage.getItem("signupPass") ? false : true
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchUserStatus = async ({ username }) => {
    axios
      .post("checkUserStatus", {
        username: userName,
      })
      .catch((error) => {
        if (
          error.response.data.message ===
            "Access Blocked By Admin. Try With Another Email" ||
          error.response.data.message === "User is Blocked By Admin."
        ) {
          // console.log("User kicked");
          localStorage.setItem("status", "deact");
          gameSignOut();
        }
      });
  };
  const fetchUserBalance = ({ userName }) =>
    axios
      .post("checkUserCreditBalance", { userName })
      .then((res) => {
        // console.log({ creditData: res.data.response.Credit });
        localStorage.setItem("previousCredit", res.data.response.Credit);
        setUserCredit({
          Credit: res.data.response.Credit,
        });
      })
      .catch((err) => console.log({ err }));

  const updateUserWallet = () =>
    axios
      .post("updateUserBalance", {
        userName,
      })
      .catch((error) => {
        console.log({
          error,
        });
      });

  useEffect(() => {
    if (userName) {
      fetchUserBalance({ userName });
    }
    const timer = setInterval(() => {
      fetchUserStatus({ userName });
      fetchUserBalance({ userName });
      updateUserWallet();
      getUserCredit();
    }, 5000);
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userName]);

  useEffect(() => {
    let data = {
      bonus: 1,
    };
    dispatch(getGameList(data));
    setgameList(gameData.GameList);
    const timer = setTimeout(() => {
      getUserCredit();
    }, 1000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameLoading, userData, userCredit]);

  const getUserCredit = () => {
    const uData = JSON.parse(userData);
    setUserName(uData.Username);
    dispatch(checkUserCredit(uData.Username));
  };

  const onOpenGameHandler = (gameid) => {
    const uData = JSON.parse(userData);

    let back_url = `${game_url}/backurl`;
    let encoded = base64_encode(back_url);
    const url = `${game_url}?id=${gameid}&token=${uData.Token}&back=${encoded}==&lang=en`;
    Setgameurl(url);
    setModalGameShow(true);
  };

  const gameSignOut = async () => {
    const uData = JSON.parse(userData);
    dispatch(userLogout(uData.Username, navigate));
  };

  const paymentStripe = (val, amt) => {
    console.log("test payment" + val);
    if (val === "debit") {
      setDepoamt(amt);
      setShowDeposit(false);
      setShowStripePayment(true);
      setShowPayment(true);
    } else {
      setPaymentErr("Some error please select another payment");
      setErrorShow(true);
    }
  };

  const handleChangePass = (uname, pass, newpass) => {
    const changePassdata = {
      username: uname,
      password: pass,
      newPassword: newpass,
    };
    dispatch(userChangePassword(changePassdata));
    dispatch(userLoginError());
  };

  const pageReload = () => {
    const uData = JSON.parse(userData);
    setErrorShow(false);
    dispatch(checkUserCredit(uData.Username));
  };

  const handleClose = () => {
    fetchCredit();
    setModalGameShow(false);
  };

  const paymentRedeem = (amt) => {
    console.log(amt);
    setshowRedeem(false);
  };
  const showStatus = (val) => {
    console.log(val);
    setShowStripePayment(false);
    setShowPayment(false);
    setPaymentErr(val);
    setErrorShow(true);
  };
  const hidePayment = () => {
    setShowPayment(false);
  };

  const [orientation, setOrientation] = useState("");
  const [showOrImg, setShowOrImg] = useState(true);
  useEffect(() => {
    if (document.body.requestFullscreen) {
      document.body.requestFullscreen();
    } else if (document.body.webkitRequestFullscreen) {
      /* Safari */
      document.body.webkitRequestFullscreen();
    } else if (document.body.msRequestFullscreen) {
      /* IE11 */
      document.body.msRequestFullscreen();
    }

    /*Screen Orientation  */
    const mql = window.matchMedia("(orientation: portrait)");

    // If there are matches, we're in portrait
    if (mql.matches) {
      // alert('orOne')
      setOrientation("portrait");
      console.log("change to portrait");
      setShowOrImg(true);
    } else {
      //alert('orTwo')
      // Landscape orientation
      setOrientation("landscape");
      console.log("change to landscape");
      setShowOrImg(false);
    }

    // Add a media query change listener
    mql.addListener(function (m) {
      if (m.matches) {
        setOrientation("portrait");
        setShowOrImg(true);
        console.log("change to portrait func");
      } else {
        setOrientation("landscape");
        setShowOrImg(false);
        console.log("change to landscape func");
      }
    });
  }, [orientation]);

  const fetchCredit = async () => {
    const { data } = await axios.post("/checkUserCreditBalance", {
      userName,
    });

    if (data) {
      setUserCredit(data.response.Credit);
    }
  };

  const toggleMusic = () => {
    setBackgroundMusicStatus((prev) =>
      prev === "PLAYING" ? "STOPPED" : "PLAYING"
    );
  };
  const showFullscreen = () => {
    if (document.body.requestFullscreen) {
      document.body.requestFullscreen();
    } else if (document.body.webkitRequestFullscreen) {
      /* Safari */
      document.body.webkitRequestFullscreen();
    } else if (document.body.msRequestFullscreen) {
      /* IE11 */
      document.body.msRequestFullscreen();
    }
  };

  return (
    <div
      onClick={() => showFullscreen()}
      className={`${
        orientation.includes("landscape")
          ? "gameList__wrapper"
          : "gameList__wrapper__potrait"
      }`}
    >
      {showOrImg && <div className="orientLoader"></div>}
      <ReactSound
        url="./assets/julia-133094.mp3"
        playStatus={backgroundMusicStatus}
        loop
        volume={5}
      />
      <div className="gameList__header">
        <button
          onClick={() => setShowUserModal(true)}
          className="gameList__header__avatarContainer"
        >
          <div className="gameList__header__avatarContainer__imageWrapper">
            <img src="./assets/demoAvatar.png" alt="" />
          </div>
          <p>{JSON.parse(userData).Username}</p>
        </button>

        <div className="gameList__header__amountInfo__wrapper">
          <img src="./assets/goldCoin.png" alt="" />
          <p>{userCredit?.Credit}</p>
        </div>

        <CashbackSpinner />

        <button
          onClick={() => setChangePassmodal(true)}
          className="gameList__header__icon__button"
        >
          <img src="./assets/settingsIcon.svg" alt="" />
        </button>
        <button
          onClick={gameSignOut}
          className="gameList__header__icon__button"
        >
          <img src="./assets/logout.svg" alt="" />
        </button>
      </div>
      <Toaster />

      <UserModal
        userModalshow={showUserModal}
        onHide={() => setShowUserModal(false)}
        user={JSON.parse(userData)}
      />
      <Toaster />
      <PasswordModal
        userModalshow={showPasswordModal}
        onHide={() => setshowPasswordModal(false)}
        user={localStorage.getItem("signupPass")}
      />
      <ChangePassModal
        backgroundMusicStatus={backgroundMusicStatus}
        toggleMusic={toggleMusic}
        userChangePassshow={changePassmodal}
        onHide={() => setChangePassmodal(false)}
        user={JSON.parse(userData)}
        changePassword={handleChangePass}
        msg={Errdata}
      />
      <Gamemodal show={modalShow} onHide={() => setModalShow(false)} />
      <Paymentmodal
        show={showDeposit}
        onHide={() => setShowDeposit(false)}
        paymentStripe={paymentStripe}
      />
      <Depositmodal
        show={showRedeem}
        onHide={() => setshowRedeem(false)}
        paymentRedeem={paymentRedeem}
      />
      <GameplayModal
        show={ModalGameShow}
        onHide={() => handleClose(false)}
        gurl={gameurl}
      />
      <ErrorModal
        errorshow={errorShow}
        onHide={() => pageReload()}
        message={paymentErr}
      />
      {/* <Gamemodal show={modalShow} onHide={() => setModalShow(false)} />*/}
      <GameplayModal
        show={show}
        onHide={() => handleClose(false)}
        gurl={gameurl}
      />

      {showStripePayment && (
        <Stripe
          showPayment={showPayment}
          hidePayment={hidePayment}
          gameUser={userName}
          amt={depoamt}
          paymentStatus={showStatus}
        />
      )}

      <div className="gameList__middle__container">
        <marquee>
          {notificationData.message.length > 0 &&
            notificationData.message.map(function (item, i) {
              return item.description + " | ";
            })}
        </marquee>
        {/* <div className="gameList__left__sidebar">
          <button
            className="left__sidebar__button"
            onClick={() => navigate("/gamelist")}
          >
            <img src="./assets/allIcon.png" alt="" />
            <p>All</p>
          </button>
          <button className="left__sidebar__button">
            <img src="./assets/fishingIcon.png" alt="" />
            <p>Fishing</p>
          </button>
          <button className="left__sidebar__button">
            <img src="./assets/slotIcon.png" alt="" />
            <p>Slot</p>
          </button>
          <button className="left__sidebar__button">
            <img src="./assets/casinoIcon.png" alt="" />
            <p>Casino</p>
          </button>
        </div> */}

        <div className="gameList__carousel">
          {gamelist && gamelist.length > 0 && (
            <Swiper slidesPerView={3} spaceBetween={50}>
              {gamelist.map((item, index) => (
                <SwiperSlide
                  className="gameList__list__item"
                  key={item.GameID}
                  // onClick={() => {
                  //   document.body.requestFullscreen();
                  // }}
                  onClick={() => {
                    onOpenGameHandler(item.GameID);
                  }}
                >
                  <img
                    src={item.Image1}
                    alt="aerodynamci"
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                      width: "15vw",
                      height: "40vh",
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
      </div>

      <div className="gameList__bottom__image__container">
        <button
          className="gameList__button__bg"
          onClick={() => setShowDeposit(true)}
        >
          Deposit
        </button>
        <DailySpin />
        <button
          className="gameList__button__bg"
          onClick={() => setshowRedeem(true)}
        >
          Redeem
        </button>
      </div>
    </div>
  );
};

export default GameList;
