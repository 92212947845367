import React from "react";
import Footer from "../../component/Footer/Footer";
import Navbar from "../../component/Navbar";
import "./aboutUs.css";

function AboutUs() {
  return (
    <div className="container__wrapper">
      <Navbar />
      <img src="./assets/topLine.png" alt="aerodynamic" />
      <main className="main__container">
        <section>
          <h1>Welcome to Aerodynamic LLC </h1>
          <p>
            At Aerodynamic LLC, we take your gaming experience to soaring
            heights, providing you with the most thrilling and immersive casino
            games on the web. Our mission is to create an exhilarating online
            gaming environment where you can enjoy an array of games while
            feeling the rush of winning big.
          </p>
        </section>

        <section>
          <h2>Who We Are</h2>
          <p>
            Aerodynamic LLC is a dynamic and innovative online casino game
            provider dedicated to delivering top-tier entertainment to players
            around the world. With a team of passionate developers, designers,
            and gaming enthusiasts, we've crafted a platform that's not just
            about winning but also about having a blast while you play.
          </p>
        </section>
        <section>
          <h2>Why Choose Aerodynamic LLC?</h2>
          <ol>
            <li>
              <b>Fair Play:</b>We prioritize fairness and transparency in all
              our games, ensuring that every player has a genuine chance to win.
            </li>
            <li>
              <b>Security:</b> Your safety is our top priority. We employ
              cutting-edge security measures to protect your personal and
              financial information.
            </li>
            <li>
              <b>24/7 Customer Support:</b> Our dedicated support team is
              available around the clock to assist you with any questions or
              concerns.
            </li>
            <li>
              <b>Mobile Gaming:</b> Play anytime, anywhere, on your mobile
              device, ensuring you never miss a moment of excitement.
            </li>
            <li>
              <b>Responsible Gaming:</b> We promote responsible gaming and
              provide tools to help you stay in control of your gambling
              activities.
            </li>
          </ol>
        </section>
        <section>
          <h2>Join the Aerodynamic LLC Community</h2>
          <p>
            Join our community of gaming enthusiasts and experience the thrill
            of casino gaming like never before. Whether you're here for the
            excitement, the big wins, or simply the fun, Aerodynamic LLC is your
            go-to destination.
            <br />
            Explore our games, enjoy the bonuses, and let the adventure begin.
            Welcome to Aerodynamic LLC, where the sky's the limit!
          </p>
        </section>
      </main>
      <img src="./assets/bottomLine.png" alt="aerodynamic" />
      <Footer />
    </div>
  );
}

export default AboutUs;
