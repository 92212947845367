import React from 'react'
import  "./gamemodal.css";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
function UserModal(props) {
  return (
    <div >
     <Modal show={props.userModalshow} onHide={props.onHide} backdrop="static"
        keyboard={false}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered dialogClassName="my-modal" contentClassName="modaluserProfile">
        <Modal.Header closeButton style={{position: 'absolute',backgroundColor: '#ffffff',
        right: 0,borderRadius: '40px',padding: '7px 3px',zIndex:10}}>
        
        </Modal.Header>
        <Modal.Body>
        <div className='userModal'>
        <div className='usertModalErrorDiv'>
                Welcome :    {props.user.Username}
        </div>
        </div>
        </Modal.Body>
        
        
      </Modal>
      </div>
  )
}

export default UserModal;