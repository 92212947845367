import React from 'react'
import  "./gamemodal.css";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
function ErrorModal(props) {
  return (
    <div >
     <Modal show={props.errorshow} onHide={props.onHide} backdrop="static"
        keyboard={false}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered dialogClassName="my-modal" contentClassName="modalErrorPAyment">
        <Modal.Header closeButton style={{position: 'absolute',backgroundColor: '#ffffff',
        right: 0,borderRadius: '40px',padding: '7px 3px',zIndex:10}}>
        
        </Modal.Header>
        <Modal.Body>
        <div className='paymentModal'>
        <div className='paymentModalErrorDiv'>
                    {props.message}
        </div>
        </div>
        </Modal.Body>
        
        
      </Modal>
      </div>
  )
}

export default ErrorModal