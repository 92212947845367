import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetError, userLogin } from "../../store/actions/authAction";
import { getGameList } from "../../store/actions/gameAction";
import backgroundVideo from "../../utilis/assets/backgroundAsset06.mp4";
import loginCSS from "./login.module.css";

const Login = () => {
  const data = useSelector((state) => state.authRoot.ErrMessage);
  const userData = useSelector((state) => state.authRoot.UserLogin);
  const spinnLoad = useSelector((state) => state.authRoot.spinnerLoading);
  const navigate = useNavigate();
  const [userName, setuserName] = useState("");
  const [createuserName, setcreateuserName] = useState("");
  const [userPass, setuserPass] = useState("");
  const [isRem, setIsRem] = useState(false);
  const [show, setShow] = useState("");
  const [toggleLogin, settoggleLogin] = useState(false);
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [showOrImg, setShowOrImg] = useState(true);
  const [orientation, setOrientation] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    // console.log(userData);
    let Gamedata = {
      bonus: 1,
    };
    dispatch(getGameList(Gamedata));

    if (document.body.requestFullscreen) {
      document.body.requestFullscreen();
    } else if (document.body.webkitRequestFullscreen) {
      /* Safari */
      document.body.webkitRequestFullscreen();
    } else if (document.body.msRequestFullscreen) {
      /* IE11 */
      document.body.msRequestFullscreen();
    }

    const mql = window.matchMedia("(orientation: portrait)");
    if (mql.matches) {
      // alert('orOne')
      setOrientation("portrait");
      console.log("change to portrait");
      setShowOrImg(true);
    } else {
      //alert('orTwo')
      // Landscape orientation
      setOrientation("landscape");
      console.log("change to landscape");
      setShowOrImg(false);
    }
    mql.addListener(function (m) {
      if (m.matches) {
        setOrientation("portrait");
        setShowOrImg(true);
        console.log("change to portrait func");
      } else {
        setOrientation("landscape");
        setShowOrImg(false);
        console.log("change to landscape func");
      }
    });
  }, [data, userData, spinnLoad, orientation]);
  const loginHandler = async () => {
    setLoadingSpin(true);
    const data = {
      userName,
      userPass,
      type: "login",
    };
    dispatch(userLogin(data, navigate));
    setLoadingSpin(false);
    // dispatch(userToken(data.userName,navigate));
    // dispatch(checkUserCredit(data.userName));
  };

  const signupHandler = async () => {
    setLoadingSpin(true);
    const data = {
      userName: createuserName,
      type: "signup",
    };

    dispatch(userLogin(data, navigate));
    setLoadingSpin(false);
  };

  const showlogin = () => {
    setcreateuserName("");
    settoggleLogin(false);
    dispatch(resetError());
  };
  const showSignup = () => {
    setuserName("");
    setuserPass("");
    settoggleLogin(true);
    dispatch(resetError());
  };

  const showFullscreen = () => {
    if (document.body.requestFullscreen) {
      document.body.requestFullscreen();
    } else if (document.body.webkitRequestFullscreen) {
      /* Safari */
      document.body.webkitRequestFullscreen();
    } else if (document.body.msRequestFullscreen) {
      /* IE11 */
      document.body.msRequestFullscreen();
    }
  };

  return (
    <div
      onClick={() => showFullscreen()}
      className={`${
        orientation.includes("landscape")
          ? "gameList__wrapper"
          : "gameList__wrapper__potrait"
      }`}
    >
      {showOrImg && <div className="orientLoader"></div>}

      <div className="video__overlay" />
      <video
        className="video__container"
        loop
        autoPlay
        playsinline="true"
        disablePictureInPicture="true"
      >
        <source src={backgroundVideo} />
      </video>

      <div className={loginCSS.container}>
        <div className={loginCSS.bottom_graphics}>
          <img
            className={loginCSS.graphics_image_top}
            src="./assets/topLine.png"
            alt="aerodynamic"
            style={{ width: "100%" }}
          />
        </div>

        <div className={loginCSS.loading}>
          <div className={loginCSS.frmContainer}>
            <img
              className={loginCSS.loadingImg}
              src="./assets/aerodynamic.png"
              alt="aerodynamic"
            />
            {toggleLogin ? (
              <div>
                <Form.Group className="mb-3">
                  <Form.Label style={{ color: "#ffffff" }}>Name</Form.Label>
                  <Form.Control
                    type="text"
                    style={{
                      background: "black",
                      color: "white",
                    }}
                    placeholder="Enter Name"
                    onChange={(e) => setcreateuserName(e.target.value)}
                    value={createuserName}
                  />
                </Form.Group>
                <div style={{ color: "yellow" }}>
                  {!data.message ? "" : data.message}
                </div>
                <Row>
                  <Col md={5}>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Check
                        type="checkbox"
                        label="Remember me"
                        style={{ color: "#ffffff" }}
                        onChange={(e) => setIsRem(isRem)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={7}>
                    <Form.Group className="mb-9" controlId="formBasicCheckbox">
                      <Form.Label style={{ color: "#ffffff" }}>
                        Already have an account?
                        <span
                          style={{ color: "#ffff7a", cursor: "pointer" }}
                          onClick={showlogin}
                        >
                          {" "}
                          Login
                        </span>
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Row>

                <div style={{ textAlign: "center" }}>
                  <Button
                    variant="none"
                    type="button"
                    className="auth_btn"
                    onClick={signupHandler}
                  >
                    {spinnLoad ? (
                      <Spinner animation="border" variant="warning" />
                    ) : (
                      "Signup"
                    )}
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <Form.Group className="mb-1">
                  <Form.Label style={{ color: "#ffffff" }}>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Name"
                    style={{
                      background: "black",
                      color: "white",
                    }}
                    onChange={(e) => setuserName(e.target.value)}
                    value={userName}
                  />
                </Form.Group>
                <Form.Group className="mb-1">
                  <Form.Label style={{ color: "#ffffff" }}>Password</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Password"
                    style={{
                      background: "black",
                      color: "white",
                    }}
                    onChange={(e) => setuserPass(e.target.value)}
                    value={userPass}
                  />
                </Form.Group>

                <div style={{ color: "yellow" }}>
                  {!data.message ? "" : data.message}
                </div>
                <Row>
                  <Col md={5}>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Check
                        type="checkbox"
                        label="Remember me"
                        style={{ color: "#ffffff" }}
                        onChange={(e) => setIsRem(!isRem)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={7}>
                    <Form.Group className="mb-9" controlId="formBasicCheckbox">
                      <Form.Label style={{ color: "#ffffff" }}>
                        Create new account?
                        <span
                          style={{ color: "#ffff7a", cursor: "pointer" }}
                          onClick={showSignup}
                        >
                          {" "}
                          Signup
                        </span>
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Row>

                <div style={{ textAlign: "center" }}>
                  <Button
                    variant="none"
                    type="button"
                    className="auth_btn"
                    onClick={loginHandler}
                  >
                    {spinnLoad ? (
                      <Spinner animation="border" variant="warning" />
                    ) : (
                      "Login"
                    )}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={loginCSS.bottom_graphics}>
          <img
            className={loginCSS.graphics_image_bottom}
            src="./assets/topLine.png"
            alt="aerodynamic"
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
