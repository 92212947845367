import React, { useEffect, useState } from "react";
import Navbar from "../../component/Navbar";
import axios from "../../services/axios";
import { useParams } from "react-router-dom";

const SingleBlog = () => {
  const [blogs, setBlogs] = useState(null);

  const { blog_id } = useParams();

  useEffect(() => {
    axios.get("/admin/getBlog").then((res) => {
      if (res) {
        setBlogs(res.data.data.find((blog) => blog._id === blog_id));
      }
    });
  }, [blog_id]);

  return (
    <div className="container__wrapper">
      <Navbar />
      <main className="main__container">
        {blogs && (
          <>
            <div className="blog__content">
              <h1>{blogs.title}</h1>
              <p className="blog__content__sub__title">{blogs.sub_title}</p>

              <img src={blogs.image} alt="" />

              <div
                className="blog__content__description"
                dangerouslySetInnerHTML={{ __html: blogs.description }}
              ></div>
            </div>
          </>
        )}
      </main>
    </div>
  );
};

export default SingleBlog;
