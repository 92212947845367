import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const Iframe = ({ source }) => {

    if (!source) {
        return <Spinner/>;
    }

    const src = source;     
    return (
        <div className="col-md-12">
        <div className="embed-responsive embed-responsive-16by9">
            <iframe className="embed-responsive-item"  src={src} title='gameLoader'  allowfullscreen 
            style={{width:'100vw', height:'100vh'}}></iframe>
            </div>
            </div>
       
    );
};

export default Iframe;