import axios from "../../services/axios";
import { checkUserCredit } from "./gameAction";

export const userLoginHelper = (data) => {
  return {
    type: "SET_USER_AUTH",
    payload: data,
  };
};

export const userTokenHelper = (data) => {
  return {
    type: "SET_USER_TOKEN",
    payload: data,
  };
};

export const userTokenValidityHelper = (data) => {
  return {
    type: "CHECK_USER_TOKEN",
    payload: data,
  };
};

export const userLogoutHelper = (data) => {
  return {
    type: "USERS_LOGOUT",
    payload: data,
  };
};

export const userPasswordChangeHelper = (data) => {
  return {
    type: "USERS_PASSWORD_CHANGE",
    payload: data,
  };
};

export const userLoginError = (data) => {
  return {
    type: "SET_LOGIN_ERROR",
    payload: data,
  };
};

export const userAdminNotification = (data) => {
  return {
    type: "ADMIN_NOTIFICATION",
    payload: data,
  };
};

export const successCall = (data) => {
  return {
    type: "SUCCESS_LOAD",
    payload: data,
  };
};

export const errorCall = (data) => {
  return {
    type: "ERROR_LOAD",
    payload: data,
  };
};

export const resetError = () => {
  return async (dispatch) => {
    dispatch(userLoginError(""));
  };
};

export const userLogin = (inputValue, navigate) => {
  console.log("dfjdkfj", {
    inputValue,
  });

  if (inputValue.type == "signup") {
    console.log(
      "signup",
      {
        inputValue,
      },
      " jels dff"
    );
    return async (dispatch) => {
      dispatch(getNotification());
      dispatch(userLoginError(""));
      dispatch(successCall(true));
      if (inputValue.userName === "") {
        const obj = {
          message: "Enter User Name",
        };
        dispatch(userLoginError(obj));
        dispatch(successCall(false));
      } else {
        try {
          dispatch(successCall(true));
          const { data } = await axios.post("/getUserAccount", {
            userName: inputValue.userName,
          });
          console.log("Login user");
          if (data.response.ErrorCode == 12) {
            localStorage.setItem(
              "signupPass",
              JSON.stringify(data.response.Password)
            );
            dispatch(userLoginHelper(data.response));
            dispatch(userToken(inputValue.userName, navigate));
            dispatch(successCall(false));
          } else if (data.response.ErrorCode == 1) {
            localStorage.setItem(
              "signupPass",
              JSON.stringify(data.response.Password)
            );
            dispatch(userLoginHelper(data.response));
            dispatch(userToken(inputValue.userName, navigate));
            dispatch(successCall(false));
          } else if (data.response.success == false) {
            dispatch(userLoginError(data.response.message));
            dispatch(successCall(false));
          }
        } catch (err) {
          console.log(err.response.data.message);
          dispatch(userLoginError(err.response.data));
          // dispatch(userLoginError(err.response.data));
          dispatch(successCall(false));
        }
      }
    };
  } else {
    return async (dispatch) => {
      console.log("login");
      dispatch(getNotification());
      dispatch(userLoginError(""));
      if (inputValue.userName == "") {
        const obj = {
          message: "Enter User Name",
        };
        dispatch(userLoginError(obj));
      } else if (inputValue.userPass == "") {
        const obj = {
          message: "Enter User Password",
        };
        dispatch(userLoginError(obj));
      } else {
        console.log(inputValue);
        try {
          dispatch(successCall(true));
          const { data } = await axios.post("/getUserAccount", {
            userName: inputValue.userName,
            password: inputValue.userPass,
          });
          console.log(data);
          if (data.response.ErrorCode == 12) {
            localStorage.removeItem("signupPass");
            dispatch(userLoginHelper(data.response));
            dispatch(userToken(inputValue.userName, navigate));
            dispatch(successCall(false));
          }
          if (data.response.ErrorCode == 1) {
            localStorage.removeItem("signupPass");
            dispatch(userLoginHelper(data.response));
            dispatch(userToken(inputValue.userName, navigate));
            dispatch(successCall(false));
          }
          if (data.success == true) {
            console.log("djfkdjfkdjf");
            dispatch(userLoginHelper(data.response));
            dispatch(userToken(inputValue.userName, navigate));
            dispatch(successCall(false));
          }
        } catch (err) {
          console.log("error code");
          // dispatch(userLoginError(err.response.data));
          dispatch(userLoginError(err.response.data));
          dispatch(successCall(false));
        }
        dispatch(successCall(false));
      }
    };
  }
};

export const userToken = (token, navigate) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post("/userTokenRequest", {
        userName: token,
      });
      //console.log(data);
      dispatch(userTokenHelper(data.response));
      dispatch(checkUserCredit(token));
      dispatch(userLoginError(""));
      const obj = {
        userData: data.response,
      };
      localStorage.setItem("userData", JSON.stringify(data.response));
      navigate("/gamelist");
    } catch (err) {
      console.log("Error", err);
    }
  };
};

export const checkTokenValidity = (token) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post("/checkTokenValidity", {
        token: token,
      });
      dispatch(userTokenValidityHelper(data));
    } catch (err) {
      console.log("Error", err);
    }
  };
};

export const userChangePassword = (val) => {
  console.log("data" + val);
  return async (dispatch) => {
    dispatch(userLoginError(""));
    try {
      const { data } = await axios.post("/resetPassword", {
        username: val.username,
        password: val.password,
        newPassword: val.newPassword,
      });
      console.log(data.message);
      dispatch(userLoginError(data));
      dispatch(userPasswordChangeHelper(data));
    } catch (err) {
      console.log("Error", err);
      dispatch(userLoginError(err.response.data));
    }
  };
};

export const userLogout = (uname, navigate) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post("/signOut", {
        userName: uname,
      });
      dispatch(userLogoutHelper(data));
      localStorage.removeItem("userData");
      localStorage.removeItem("signupPass");
      dispatch(userLoginError(""));
      navigate("/");
    } catch (err) {
      console.log("Error", err);
    }
  };
};

export const getNotification = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/adminGetNews");
      dispatch(userAdminNotification(data));
    } catch (err) {
      console.log("Error", err);
    }
  };
};
