import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./splash.css";
import backgroundVideo from "../../utilis/assets/backgroundAsset06.mp4";

function Splash() {
  const navigate = useNavigate();
  const [progress, setprogress] = useState(0);
  useEffect(() => {
    const id = setInterval(() => setprogress((progress) => progress + 1), 40);

    return () => {
      clearInterval(id);
      // console.log(progress);
      if (progress >= 100) {
        navigate("/login");
      }
    };
  }, [progress]);

  return (
    <div className="splash__screen__container">
      <div className="video__overlay" />
      <video loop autoPlay playsinline="true" disablePictureInPicture="true" className="video__container">
        <source src={backgroundVideo} />
      </video>

      
      <div className="splash__content__container">
      <img
        className={"graphics_image"}
        src="./assets/topLine.png"
        alt="aerodynamic"
        style={{ width: "100%",position:'absolute',top: '0' }}
      />
        <img src="./assets/aerodynamic.png" alt="aerodynamic" />
        <div style={{width:'90vw'}}>
          <div className="splash__loader__container">
            <div className="splash__loader__label">
              <p>Loading</p>
              <p>{progress}%</p>
            </div>
            <div className="splash__loader__wrapper">
              <div
                className="splash__loader__view"
                style={{
                  width: `${progress}%`,
                }}
              />
            </div>
          </div>
          <p className="splash__message">Installing Updates</p>
        </div>
        <img
        className={"graphics_image"}
        src="./assets/topLine.png"
        alt="aerodynamic"
        style={{ width: "100%",position:'absolute',bottom: '0'  }}
      />
      </div>

      
    </div>
  );
}

export default Splash;
