import React, { useState } from 'react'
import { CardNumberElement, CardCvcElement, CardExpiryElement, useStripe, useElements} from '@stripe/react-stripe-js';

import  "./payment.css";
import Modal from 'react-bootstrap/Modal';
import {Button} from 'react-bootstrap';
import axios from '../services/axios';
const CARD_OPTIONS = {
	iconStyle: "solid",
	style: {
		base: {
			iconColor: "#c4f0ff",
			color: "black",
			fontWeight: 500,
			fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
			fontSize: "16px",
			fontSmoothing: "antialiased",
			":-webkit-autofill": { color: "black" },
			"::placeholder": { color: "black" }
		},
		invalid: {
			iconColor: "#ffc7ee",
			color: "black"
		}
	}
}
export default function PaymentForm(props) {
    console.log(props);
    const [success, setSuccess] = useState(false)
    const stripe = useStripe();
    const elements = useElements()
    const billingDetails = {
        name: 'suvasis'
        
      };
    const handleSubmit = async (e) =>{
        console.log('test');
        e.preventDefault()
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: "card",
            billing_details: billingDetails,
            card: elements.getElement(CardCvcElement, CardExpiryElement, CardNumberElement)
        })
        if (error) {
            console.log('[error]', error);
          } else {
            console.log('[PaymentMethod]', paymentMethod);
           
            const response = await axios.post("/addRemoveCredit", {
                "userName": props.gameUser,
                "amount": Number(props.amt)*100,
                "transactionType":"debit"
              });
           console.log(response);
           if (response.ErrorCode == 0) {
                props.paymentStatus('Payment Successfully')
            }
            else{
                props.paymentStatus('Some Error.Try Again!');
            }
          }

       /* if(!error){
            try {
                const {id} = paymentMethod
                const response = await axios.post("http://localhost:3000/payment", {
                    amount: 10000,
                    id
                })

                if(response.data.success){
                    console.log("Successful Payment")
                    setSuccess(true)
                }

            } catch (error) {
                console.log("Error", error)
            }
        }else {
            console.log(error.message)
        }*/
    }
   return (
    <>
    <Modal show={props.show} onHide={props.onHide} backdrop="static"
        keyboard={false}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered dialogClassName="my-modal" contentClassName="modalPAyment">
        <Modal.Header closeButton style={{position: 'absolute',backgroundColor: '#ffffff',
        right: 0,borderRadius: '40px',padding: '7px 3px',zIndex:10}}>
        
        </Modal.Header>
        <Modal.Body>

       
    {!success ? 
    <form onSubmit={handleSubmit}>
        <fieldset className='FormGroup'>
            <div className="FormRow">
                <CardNumberElement options={CARD_OPTIONS} />
            </div>
        </fieldset>
        <fieldset className='FormGroup'>
            <div className="FormRow">
                <CardExpiryElement options={CARD_OPTIONS}  />
            </div>
        </fieldset>
        <fieldset className='FormGroup'>
            <div className="FormRow">
                <CardCvcElement options={CARD_OPTIONS} />
            </div>
        </fieldset>
        <div style={{textAlign:'center'}}>
         <button  className='btnJoinPayment'>
        Pay
          
        </button>
        </div>
    </form>
    :
    <div className="payment-success">
        <h2>Payment successful</h2>
        <h3 className='Thank-you'>Thank you for your patronage</h3>
    </div>
}
</Modal.Body>
        </Modal>
</>
       )
}