/*import { loadStripe } from '@stripe/stripe-js';
import { stripeKeys } from './constants';

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(stripeKeys.test.publish_key);
  }
  return stripePromise;
};

export default getStripe;*/

import React,{useState} from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { stripeKeys } from './constants';
import PaymentForm from '../component/PaymentForm';
const stripePromise = loadStripe(stripeKeys.test.publish_key)
function Stripe(props) {
  const [showPayment,setShowPaymen]=useState(false);
  return (
   <div>
    <Elements stripe={stripePromise}>
        <PaymentForm 
         show={props.showPayment}
         onHide={props.hidePayment}
         gameUser={props.gameUser}
         amt={props.amt}
         onSuccess={props.paymentStatus}
         paymentStatus={props.paymentStatus}
         />
    </Elements>
   </div>
   )
}

export default Stripe