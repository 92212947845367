import React from 'react'
import  "./gamemodal.css";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Iframe from './Iframe';

function GameplayModal(props) {
  return (
    <div >
    <Modal show={props.show} onHide={props.onHide} backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered dialogClassName="my-modal" >
        <Modal.Header closeButton style={{position: 'absolute',right: '20px',width:'50px',
        height:'50px',backgroundColor:'white',top:'30px',borderRadius: '50px',textAlign: 'center',
        paddingRight: '20px',zIndex:9999}}>
        
        </Modal.Header>
        <Modal.Body>
        <Iframe source={props.gurl}/>
        </Modal.Body>
        
      </Modal>
      </div>
    
  )
}

export default GameplayModal;