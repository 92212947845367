import React, { useState } from "react";
import "./gamemodal.css";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
function ChangePassModal(props) {
  const [error, setError] = useState("");
  const [password, setPass] = useState("");
  const [newPass, setnewPass] = useState("");
  const submitChangePass = () => {
    console.log("test");
    if (password == "") {
      setError("Please enter Password");
    } else if (newPass == "") {
      setError("Please enter new password");
    } else {
      props.changePassword(props.user.Username, password, newPass);
      setError("");
    }
  };
  const hideModal = () => {
    setPass("");
    setnewPass("");
    props.onHide();
  };
  return (
    <div>
      <Modal
        show={props.userChangePassshow}
        onHide={() => hideModal()}
        backdrop="static"
        keyboard={false}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="my-modal"
        contentClassName="modaluserChangePass"
      >
        <Modal.Header
          closeButton
          style={{
            position: "absolute",
            backgroundColor: "#ffffff",
            right: 0,
            borderRadius: "40px",
            padding: "7px 3px",
            zIndex: 10,
          }}
        ></Modal.Header>
        <Modal.Body>
          <div className="userModal">
            <Form.Check
              onClick={props.toggleMusic}
              checked={props.backgroundMusicStatus === "PLAYING"}
              label={
                props.backgroundMusicStatus === "PLAYING"
                  ? "Music On"
                  : "Music Off"
              }
              style={{
                color: "white",
                fontWeight: "bold",
              }}
            />

            <div className="usertModalErrorDiv">
              <div className="lblChnagePassword">Change Password</div>
            </div>
            <div className="changePassForm">
              <div className="changePassLable">User Name:</div>
              <input
                type="text"
                value={props.user.Username}
                className="changePassInput"
                disable
              />
            </div>
            <div className="changePassForm">
              <div className="changePassLable">Password:</div>
              <input
                type="text"
                value={password}
                className="changePassInput"
                onChange={(e) => setPass(e.target.value)}
              />
            </div>
            <div className="changePassForm">
              <div className="changePassLable">New Password:</div>
              <input
                type="text"
                value={newPass}
                className="changePassInput"
                onChange={(e) => setnewPass(e.target.value)}
              />
            </div>
            <div style={{ color: "yellow" }}>
              {!props.msg ? "" : props.msg.message}
            </div>
            <div className="ErrorLblPassword">{error}</div>
            <div
              style={{ width: "100%", marginTop: "5px", textAlign: "center" }}
            >
              <Button
                variant="success"
                className="btnJoin"
                onClick={() => submitChangePass()}
              >
                Submit
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ChangePassModal;
