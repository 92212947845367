import React from 'react'
import  "./gamemodal.css";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
function PasswordModal(props) {
  return (
    <div >
     <Modal show={props.userModalshow} onHide={props.onHide} backdrop="static"
        keyboard={false}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered dialogClassName="my-modal" contentClassName="modalPass">
        <Modal.Header closeButton style={{position: 'absolute',backgroundColor: '#ffffff',
        right: 0,borderRadius: '40px',padding: '7px 3px',zIndex:10}}>
        
        </Modal.Header>
        <Modal.Body>
        <div className='paymentModal'>
        <div className='passwordModalErrorDiv'>
                   <b>{props.user}</b> <br/> Please save this password for , Login 
        </div>
        </div>
        </Modal.Body>
        
        
      </Modal>
      </div>
  )
}

export default PasswordModal;