import React, { useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { Link, NavLink } from "react-router-dom";

const Navbar = () => {
  const [isCollapased, setIsCollapsed] = useState(false);

  const checkFullscreen = () => {
    if (document.body.requestFullscreen) {
      document.body.requestFullscreen();
    } else if (document.body.webkitRequestFullscreen) {
      /* Safari */
      document.body.webkitRequestFullscreen();
    } else if (document.body.msRequestFullscreen) {
      /* IE11 */
      document.body.msRequestFullscreen();
    }
  };

  return (
    <header className="header__container">
      <div className="responsive__menu__container">
        <Link to="/">
          <img src="/assets/web/logo.png" alt="Logo" />
        </Link>
        <button onClick={() => setIsCollapsed(!isCollapased)}>
          <AiOutlineMenu />
        </button>
      </div>

      <div
        className={`collapased__nav ${
          isCollapased ? " isOpen " : " isClosed "
        }`}
      >
        <nav className="nav__list">
          <NavLink
            to={"/"}
            className={({ isActive }) => (isActive ? "highlited__link" : "")}
          >
            Home
          </NavLink>
          <NavLink to={"/"}>Download</NavLink>
          <NavLink
            to={"/aboutUs"}
            className={({ isActive }) => (isActive ? "highlited__link" : "")}
          >
            About Us
          </NavLink>
          <NavLink
            to={"/Contact"}
            className={({ isActive }) => (isActive ? "highlited__link" : "")}
          >
            Contact Us
          </NavLink>
          <NavLink
            to={"/blog"}
            className={({ isActive }) => (isActive ? "highlited__link" : "")}
          >
            Blog
          </NavLink>
        </nav>
        <Link
          className="header__cta"
          to={"/Splash"}
          onClick={() => {
            checkFullscreen();
          }}
        >
          Play Web Game
        </Link>
      </div>
    </header>
  );
};

export default Navbar;
