import React, { useState } from "react";
import "../CashbackSpinner/style.css";
import { Wheel } from "react-custom-roulette";
import { Button } from "react-bootstrap";
import axios from "../../../services/axios";
import { toast } from "react-hot-toast";

const CashbackSpinner = () => {
  const [mustSpin, setMustSpin] = useState(false);
  const [winner, setWinner] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [isEligibleForSpin, setIsEligibleForSpin] = useState(false);
  const [amountToCredit, setAmountToCredit] = useState(0);

  const updateLocalStorage = () => {
    let userData = localStorage.getItem("userData");

    if (userData) {
      userData = JSON.parse(userData);

      axios
        .post("checkUserCreditBalance", { userName: userData.Username })
        .then((res) => {
          console.log({ creditData: res.data.response.Credit });
          localStorage.setItem("previousCredit", res.data.response.Credit);
        });
    }
  };

  const fetchUserBalance = ({ userName }) =>
    axios.post("checkUserCreditBalance", { userName }).then((res) => {
      const previousCredit = localStorage.getItem("previousCredit");
      if (previousCredit) {
        if (parseFloat(previousCredit) > res.data.response.Credit) {
          setIsEligibleForSpin(true);
          setAmountToCredit(
            parseFloat(previousCredit) - res.data.response.Credit
          );
          setModalVisible(true);
        } else {
          toast.error("Better luck next time!");
        }
      }
    });

  const creditUser = ({ username, amount, transactionType }) =>
    axios
      .post("addRemoveCredit", {
        userName: username,
        amount: amount,
        transactionType: transactionType,
      })
      .then((res) => {
        toast.success("You have been credited successfully!");
        setModalVisible(false);
      })
      .catch((error) => {
        console.log(error);
      });

  const fetchPercentage = () =>
    axios.get("fetchPercentageValue").then((data) => {
      if (data.data.success) {
        console.log({
          dailySpinPercentage: data.data.data.DailySpinPercentage,
        });
        console.log({ cashbackPercentage: data.data.data.cashbackPercentage });

        let userData = localStorage.getItem("userData");

        if (userData) {
          userData = JSON.parse(userData);
          creditUser({
            username: userData.Username,
            amount: (amountToCredit * data.data.data.cashbackPercentage) / 100,
            transactionType: "credit",
          });
        }
      }
    });

  const data = [
    {
      option: "WIN",
      style: { backgroundColor: "#FD4C3A", textColor: "white" },
    },
    {
      option: "LOSE",
      style: { backgroundColor: "#029ADD", textColor: "white" },
    },
    {
      option: "WIN",
      style: { backgroundColor: "#FD4C3A", textColor: "white" },
    },
    {
      option: "LOSE",
      style: {
        backgroundColor: "#02CB6B",
        textColor: "white",
      },
    },
    {
      option: "WIN",
      style: { backgroundColor: "#029ADD", textColor: "white" },
    },
    {
      option: "LOSE",
      style: { backgroundColor: "#FD4C3A", textColor: "white" },
    },
    {
      option: "WIN",
      style: { backgroundColor: "#02CB6B", textColor: "white" },
    },
    {
      option: "LOSE",
      style: { backgroundColor: "#029ADD", textColor: "white" },
    },
  ];

  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  return (
    <>
      <div className="spinner__modal__button__container">
        <button
          className="spinner__modal__button cashback"
          onClick={() => {
            let userData = localStorage.getItem("userData");

            if (userData) {
              userData = JSON.parse(userData);
              fetchUserBalance({ userName: userData.Username });
            }
          }}
        >
          <img src="../assets/cashbackButtonImage.png" alt="aerodynamic" />
        </button>
      </div>

      {modalVisible && (
        <div
          className="modal__container"
          onClick={() => {
            setModalVisible(false);
          }}
        >
          <div
            className="modal__content"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <Wheel
              mustStartSpinning={mustSpin}
              prizeNumber={winner}
              data={data}
              radiusLineWidth={0}
              backgroundColors={["#3e3e3e", "#df3428"]}
              textColors={["#ffffff"]}
              pointerProps={{
                src: "./assets/knob.png",
                style: {
                  width: "80px",
                  rotate: "45deg",
                },
              }}
              fontWeight="bold"
              outerBorderWidth={20}
              outerBorderColor="#22485F"
              onStopSpinning={() => {
                if (data[winner].option.toLocaleLowerCase() === "win") {
                  toast.success("Hurray you won!");
                  fetchPercentage();
                } else {
                  updateLocalStorage(true);
                  setModalVisible(false);
                  toast.error("Better luck next time!");
                }

                setMustSpin(false);
              }}
            />
            <Button
              className="spin_button"
              onClick={() => {
                if (mustSpin || !isEligibleForSpin) {
                  return;
                }

                setMustSpin(true);
                setWinner(randomIntFromInterval(0, data.length - 1));
                // setWinner(0);
              }}
            >
              Spin
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default CashbackSpinner;
